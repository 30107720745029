import React, { useState, useEffect, useCallback } from 'react';
//
import '../admin.css';
import FileBase64 from 'react-file-base64';
import { useDropzone } from 'react-dropzone';
// import CanvasCompress from 'canvas-compress';
import axios from 'axios';
import compress from 'compress-base64';
import { impress } from '../impress';
//
import { TextField, Button, CircularProgress } from '@mui/material';
function UploadPhotos() {
  const [files, setFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchPhotos = async () => {
    try {
      const response = await axios.get(
        'https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/images/'
      );
      setPhotos(response.data);
      console.log(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const convertImages = async (pics) => {
    if (pics.length > 0) {
      let outputFiles = [...files];
      for (const item of pics) {
        const compressedBase64 = await compressBase64(item.base64, {
          maxWidthOrHeight: 1000,
          quality: 0.5,
        });

        const object = {
          title: item.name,
          base64: compressedBase64,
        };

        outputFiles.push(object);
        const compressedSize = compressedBase64.length;
        const fileSize = (compressedSize / (1024 * 1024)).toFixed(2); // Convert bytes to MB
        console.log(
          `Compressed image size: ${compressedSize} bytes (${fileSize} MB)`
        );
      }
      setFiles([...outputFiles]);
    } else {
      console.error('No images to convert.');
    }
  };
  const compressBase64 = async (base64String, options) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = options.maxWidthOrHeight;
        canvas.height = options.maxWidthOrHeight;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const compressedBase64 = canvas.toDataURL(
          'image/jpeg',
          options.quality
        );
        resolve(compressedBase64);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = base64String;
    });
  };

  const handleSubmit = () => {
    if (files.length > 0) {
      files.forEach((item) => {
        const base64Image = item.base64.split(',')[1];
        const object = {
          title: item.title,
          base64: base64Image,
        };

        axios.post(
          'https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/images/',
          object
        );
        setFiles([]);
        fetchPhotos();
      });
      // setFiles();
    } else {
      return null;
    }
  };
  const deleteAllPhotos = async () => {
    setPhotos([]);
    try {
      const response = await axios.delete(
        'https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/images/'
      );
      console.log(response.data.message);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    // fetchPhotos();
  });
  return (
    <div className="description-container">
      <div className="upload-container">
        <div className="header-container">
          <h1>Upload images here:</h1>
        </div>
        <div className="dropzone-container">
          <FileBase64
            multiple={true}
            onDone={(e) => {
              const pics = e.map((item) => ({
                name: item.name,
                base64: item.base64,
              }));
              convertImages(pics);
            }}
          />
          {/* <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )}
          </div> */}
          {/* <input type="file" onChange={handleFileChange} multiple /> */}
        </div>
        <div className="uploaded-pictures">
          {files.length > 0
            ? files.map((item, index) => (
                <img
                  src={item.base64}
                  // src={URL.createObjectURL(item)}
                  key={index}
                  className="images"
                  alt="image"
                />
              ))
            : null}
        </div>
        <div className="photo-buttons">
          <Button
            type="submit"
            style={{
              color: '#0da0f2',
              width: '45%',
              border: '1px solid #0da0f2',
            }}
            onClick={() => {
              // submitPhotos();
              handleSubmit();
            }}
          >
            Upload photos
          </Button>
          <Button
            type="submit"
            style={{
              color: '#d43a57',
              width: '45%',
              border: '1px solid #d43a57',
            }}
            onClick={() => {
              deleteAllPhotos();
            }}
          >
            Delete all photos
          </Button>
        </div>
      </div>
      <div className="mid-container"></div>
      <div className="result-container">
        <div className="result-header-container">
          <h1 className="result-h1">uploaded photos:</h1>
        </div>
        {isLoading ? (
          <div className="loading-container">
            <p>Photos are loading...</p>
            <CircularProgress />
          </div>
        ) : (
          <div className="result-photos">
            {photos.map((item, index) => (
              <img
                src={item.imageFile}
                key={item.imageDescription}
                className="images"
                alt="image"
              />
            ))}
          </div>
        )}

        {/* // ) : (
        //   <div className="result-photos">
        //     {files.map((item, index) => (
        //       <img
        //         src={item.base64}
        //         key={item.description}
        //         className="images"
        //         alt="image"
        //       />
        //     ))}
        //   </div>
        // )} */}
      </div>
    </div>
  );
}

export default UploadPhotos;
