import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import './App.css';
import './index.css';
// Main pages
import { createTheme, ThemeProvider } from '@mui/material/styles';
//
import Home from './Home';
import Sales from './components/sales/Sales';
import Admin from './components/admin/Admin';
import Login from './components/admin/login';
import Logout from './components/admin/logout';
import Contact from './components/contact/contact';
// const FourOhFour = () => {
//   const { pathname } = useLocation();

//   return (
//     <h3>
//       No match for <code>{pathname}</code>
//     </h3>
//   );
// };

const primary = '#0da0f2';
const secondary = '#d43a57';
const themeColors = createTheme({
  primary: {
    light: '#d43a57',
    main: '#0da0f2',
    dark: '#d43a57',
    contrastText: '#fff',
  },
  secondary: {
    light: '#d43a57',
    main: '#d43a57',
    dark: '#ba000d',
    contrastText: '#000',
  },
});
function App() {
  return (
    <ThemeProvider theme={themeColors}>
      <div className="app">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Sales" component={Sales} />
          <Route exact path="/Admin" component={Admin} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/Logout" component={Logout} />
          <Route exact path="/Contact" component={Contact} />
          {/* <Route path="*">
          <FourOhFour />
        </Route> */}
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default App;
