import React from "react";

function Star(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 -5 69 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="star"
        d="M28.7663 37.0762C27.9332 37.0729 27.1227 36.8046 26.4523 36.31L20.8759 32.2586C20.4132 31.9207 19.8551 31.7386 19.2822 31.7386C18.7093 31.7386 18.1512 31.9207 17.6885 32.2586L12.112 36.31C11.4395 36.7986 10.6296 37.0619 9.79831 37.0619C8.96702 37.0619 8.15703 36.7988 7.48446 36.3102C6.8119 35.8217 6.31125 35.1327 6.05429 34.3421C5.79733 33.5515 5.79726 32.6999 6.05404 31.9093L8.18411 25.3539C8.36224 24.8094 8.36282 24.2224 8.18576 23.6776C8.00869 23.1327 7.66314 22.6582 7.19894 22.3224L1.62246 18.2704C0.949972 17.7818 0.449438 17.0927 0.192594 16.3021C-0.0642505 15.5115 -0.064186 14.6599 0.192716 13.8693C0.449618 13.0787 0.95018 12.3897 1.62271 11.9011C2.29524 11.4124 3.1052 11.1493 3.93649 11.1492H10.8292C11.4021 11.1503 11.9605 10.9694 12.424 10.6328C12.8875 10.2961 13.2321 9.82089 13.4082 9.27576L15.538 2.72046C15.7948 1.92974 16.2954 1.2406 16.9679 0.751892C17.6405 0.26318 18.4505 0 19.2819 0C20.1133 0 20.9233 0.26318 21.5959 0.751892C22.2685 1.2406 22.769 1.92974 23.0258 2.72046L25.1559 9.27576C25.332 9.82087 25.6765 10.2961 26.14 10.6328C26.6034 10.9694 27.1618 11.1503 27.7346 11.1492H34.6273C35.4586 11.1493 36.2686 11.4124 36.9411 11.9011C37.6137 12.3897 38.1142 13.0787 38.3711 13.8693C38.628 14.6599 38.628 15.5115 38.3712 16.3021C38.1143 17.0927 37.6138 17.7818 36.9413 18.2704L31.3649 22.3218C30.9008 22.6578 30.5554 23.1325 30.3784 23.6774C30.2014 24.2224 30.202 24.8094 30.38 25.3539L32.5101 31.9093C32.7764 32.6991 32.7811 33.5537 32.5235 34.3464C32.2659 35.1391 31.7597 35.8276 31.08 36.31C30.4097 36.8045 29.5993 37.0729 28.7663 37.0762V37.0762Z"
        fill="white"
      />
    </svg>
  );
}

export default Star;
