import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import '../sales.css';
import {
  Button,
  TextField,
  Tooltip,
  IconButton,
  Icon,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StarIcon from '@mui/icons-material/Star';
//
import InfiniteScroll from 'react-infinite-scroll-component';
//
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { storeItems } from '../../../actions/imagePost';
function Photos1({ themeMode, user }) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.imageReducer.items);
  const [photos, setPhotos] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [disableFetch, setDisableFetch] = useState(true);

  const fetchPost = async () => {
    try {
      const response = await axios.get(
        `https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/images/pages/?page=${page}&limit=${limit}`
      );
      setPage(page + 1);
      dispatch(storeItems(response.data.imagePosts));
      setPhotos((prevPhotos) => {
        const updatedPhotos = [...prevPhotos, ...response.data.imagePosts];
        if (
          updatedPhotos.length >=
          response.data.imagePosts.length * response.data.totalPages
        ) {
          setHasMore(false);
          setDisableFetch(false);
          // dispatch(storeItems(response.data.imagePosts));
          // localStorage.setItem('photos', JSON.stringify(updatedPhotos));
        }
        return updatedPhotos;
      });
      setTotalItems(response.data.totalPosts);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  // Modal Logix
  const [editingDescription, setEditingDescription] = useState(false);
  const [modalPhoto, setModalPhoto] = useState(null);
  const [modalIndex, setModalIndex] = useState(null);
  const [modalInfoView, setModalInfoView] = useState(false);
  const [modalPicInfo, setModalPicInfo] = useState({
    photo: null,
    title: null,
    price: null,
    description: null,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = (photoIndex) => {
    setModalIndex(photoIndex);
    setModalIsOpen(true);
    setModalPicInfo({
      photo: photos[photoIndex].imageFile,
      title: photos[photoIndex].imageTitle,
      price: photos[photoIndex].imagePrice,
      description: photos[photoIndex].imageDescription,
    });
  };
  const closeModal = () => {
    setModalPicInfo({
      photo: null,
      title: null,
      price: null,
      description: null,
    });
    setModalIndex(null);
    setModalIsOpen(false);
  };
  const handlePrevClick = () => {
    const prevIndex = modalIndex === 0 ? photos.length - 1 : modalIndex - 1;

    setModalPicInfo({
      photo: photos[prevIndex].imageFile,
      title: photos[prevIndex].imageTitle,
      price: photos[prevIndex].imagePrice,
      description: photos[prevIndex].imageDescription,
    });
    setModalIndex(prevIndex);
  };
  const handleNextClick = (index, item) => {
    const nextIndex = modalIndex === photos.length - 1 ? 0 : modalIndex + 1;

    setModalPicInfo({
      photo: photos[nextIndex].imageFile,
      title: photos[nextIndex].imageTitle,
      price: photos[nextIndex].imagePrice,
      description: photos[nextIndex].imageDescription,
    });
    setModalIndex(nextIndex);
  };

  // Editing items logic
  const [editingIndex, setEditingIndex] = useState(null);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const updateTitle = (item) => {
    axios
      .patch(
        `https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/images/${item._id}`,
        {
          imageTitle: title,
        }
      )
      .then((response) => {
        console.log(response);
        const updatedPhotos = photos.map((photo) => {
          if (photo._id === item._id) {
            return { ...photo, imageTitle: title };
          }
          return photo;
        });
        setPhotos(updatedPhotos);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updatePrice = (item) => {
    axios
      .patch(
        `https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/images/${item._id}`,
        {
          imagePrice: price,
        }
      )
      .then((response) => {
        console.log(response);
        const updatedPhotos = photos.map((photo) => {
          if (photo._id === item._id) {
            return { ...photo, imagePrice: price };
          }
          return photo;
        });
        setPhotos(updatedPhotos);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSubmit = (e, item) => {
    e.preventDefault();
    updateTitle(item);
    updatePrice(item);
  };
  const handleEditClick = (index, item) => {
    setEditingIndex(index);
    setTitle(item.imageTitle);
    setPrice(item.imagePrice);
  };
  const handleDelete = (itemId) => {
    axios
      .delete(
        `https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/images/${itemId}`
      )
      .then((response) => {
        console.log(response, itemId);
      });
  };

  // Likes
  const [likes, setLikes] = useState({});
  const [liked, setLiked] = useState(false);
  const [disableLike, setDisableLike] = useState(false);
  const hasLiked = (itemId) => {
    return localStorage.getItem(`likes_${itemId}`) === 'liked';
  };
  const likeImage = (item) => {
    setDisableLike(true);
    const itemId = item._id;
    const hasPreviouslyLiked = hasLiked(itemId);

    if (!hasPreviouslyLiked) {
      // User is liking the image
      axios
        .patch(
          `https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/images/${itemId}`,
          {
            imageLikes: item.imageLikes + 1,
          }
        )
        .then((response) => {
          console.log(response);

          // Update state to reflect the like
          const updatedPhotos = photos.map((photo) => {
            if (photo._id === itemId) {
              return { ...photo, imageLikes: (photo.imageLikes || 0) + 1 };
            }
            return photo;
          });
          setPhotos(updatedPhotos);
          setLikes((prevLikes) => ({
            ...prevLikes,
            [itemId]: prevLikes[itemId] ? prevLikes[itemId] + 1 : 1,
          }));
          setLiked((prevLiked) => ({
            ...prevLiked,
            [itemId]: true,
          }));

          // Store in local storage that this item has been liked
          localStorage.setItem(`likes_${itemId}`, 'liked');
          setDisableLike(false);
        })
        .catch((error) => {
          console.error('Error liking image:', error);
          setDisableLike(false);
        });
    } else {
      // User is unliking the image
      axios
        .patch(
          `https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/images/${itemId}`,
          {
            imageLikes: item.imageLikes - 1,
          }
        )
        .then((response) => {
          console.log(response);

          // Update state to reflect the unlike
          const updatedPhotos = photos.map((photo) => {
            if (photo._id === itemId) {
              return { ...photo, imageLikes: (photo.imageLikes || 0) - 1 };
            }
            return photo;
          });
          setPhotos(updatedPhotos);
          setLikes((prevLikes) => ({
            ...prevLikes,
            [itemId]: prevLikes[itemId] - 1,
          }));
          setLiked((prevLiked) => ({
            ...prevLiked,
            [itemId]: false,
          }));

          // Remove from local storage that this item has been liked
          localStorage.removeItem(`likes_${itemId}`);
          setDisableLike(false);
        })
        .catch((error) => {
          console.error('Error unliking image:', error);
          setDisableLike(false);
        });
    }
  };

  useEffect(() => {
    fetchPost();

    // const cachedPhotos = localStorage.getItem('photos');
    // if (cachedPhotos) {
    //   // localStorage.removeItem('photos');
    //   console.log(localStorage);
    //   setDisableFetch(false);
    //   setPhotos(JSON.parse(cachedPhotos));
    // } else {
    //   fetchPost();
    //   setDisableFetch(true);
    // }

    // Like logic
    const isItemLiked = localStorage.getItem(`likes_${liked._id}`);
    setLiked(!!isItemLiked);
  }, []);

  return (
    <div
      className="photo-container"
      style={{
        background: themeMode ? '#212225' : 'whitesmoke',
        // alignItems: photos.length <= 3 ? 'flex-start' : 'center',
        alignItems: 'center',
        height: photos.length <= 3 && null,
      }}
      id="scrollableDiv"
    >
      <InfiniteScroll
        dataLength={photos.length}
        next={fetchPost}
        hasMore={hasMore}
        loader={
          <div
            className="loader"
            style={{ background: themeMode ? '#212225' : 'whitesmoke' }}
          >
            <p style={{ color: themeMode ? 'white' : '#212225' }}>
              Loading more Items...
            </p>
            <CircularProgress />
          </div>
        }
        endMessage={
          <div
            className="end-message"
            style={{
              color: themeMode ? 'white' : 'black',
            }}
          >
            No more items to load!
          </div>
        }
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        scrollThreshold={0.1}
        scrollableTarget="scrollableDiv"
      >
        {photos.map((item, index) => {
          return (
            <div>
              <div
                className="item-card-container"
                style={{ background: themeMode ? '#2e3135' : 'white' }}
                key={index}
                name={item}
              >
                {/* Edit item modal */}
                {user && editingIndex === index && (
                  <div
                    className="edit-item-modal"
                    style={{
                      background: themeMode ? '#212225' : 'whitesmoke',
                    }}
                  >
                    <div className="modal-top-container">
                      <h1 style={{ color: themeMode ? 'white' : 'black' }}>
                        Edit Item details
                      </h1>
                      <IconButton
                        onClick={() => setEditingIndex(null)}
                        style={{ height: 30, width: 30 }}
                      >
                        <CloseIcon style={{ color: '#e54666' }} />
                      </IconButton>
                    </div>
                    <div className="seperator"></div>
                    <form
                      onSubmit={(e) => handleSubmit(e, item)}
                      className="submit-form"
                    >
                      <TextField
                        variant="outlined"
                        style={{ width: '90%' }}
                        size="small"
                        label="Change title"
                        value={title}
                        onChange={(event) => {
                          setTitle(event.target.value);
                        }}
                        sx={{
                          '& label.Mui-focused': {
                            color: themeMode && 'white',
                          },
                          '& label': {
                            color: themeMode && '#0da0f2',
                          },
                          // focused color for input with variant='outlined'
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: themeMode && '#0da0f2',
                            },
                          },
                        }}
                        InputProps={{
                          style: {
                            color: themeMode ? 'white' : 'black',
                          },
                        }}
                      />
                      <TextField
                        variant="outlined"
                        style={{ width: '90%', marginTop: 20 }}
                        size="small"
                        label={item.imagePrice ? 'Change Price' : 'Add Price'}
                        value={price}
                        onChange={(event) => {
                          setPrice(event.target.value);
                        }}
                        sx={{
                          '& label.Mui-focused': {
                            color: themeMode && 'white',
                          },
                          '& label': {
                            color: themeMode && '#3cd68c',
                          },
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: themeMode && '#3cd68c',
                            },
                          },
                        }}
                        InputProps={{
                          style: {
                            color: themeMode ? 'white' : 'black',
                          },
                        }}
                      />
                      <Button
                        style={{
                          position: 'absolute',
                          bottom: 10,
                          right: 15,
                          color: '#29a383',
                          background: '#29a3830d',
                          border: '1px solid #29a383',
                          padding: '2px 5px',
                          fontSize: 12,
                        }}
                        type="submit"
                      >
                        Update Item
                      </Button>
                    </form>
                  </div>
                )}
                <div
                  className="item-card-img-container"
                  key={index}
                  data-index={index}
                >
                  <img
                    src={`${item.imageFile}`}
                    onClick={() => {
                      // setClickedPhoto(item);
                      openModal(index);
                    }}
                    alt={item.imageFile}
                    className="img"
                    style={{
                      background: themeMode ? '#111113' : '#818181',
                    }}
                  />
                  {/* <div
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                  >
                    <IconButton style={{}}>
                      <StarIcon style={{ fontSize: 25, color: 'white' }} />
                    </IconButton>
                  </div> */}
                </div>
                <div
                  className="seperator"
                  style={{ background: '#2b2b2b' }}
                ></div>
                <div
                  className="item-card-badges"
                  onClick={() => {
                    handleEditClick(index, item);
                    console.log(item);
                  }}
                >
                  <div
                    className="title-badge"
                    style={{
                      background: '#0da0f224',
                      color: '#0da0f2',
                      border: '1px solid #0da0f2',
                      cursor: user ? 'pointer' : 'auto',
                      fontSize: 14,
                    }}
                  >
                    {item.imageTitle ? item.imageTitle : `Item ${index + 1}`}
                  </div>
                  {item.imagePrice && (
                    <div
                      className="title-badge"
                      style={{
                        marginLeft: 5,
                        background: '#46fea526',
                        color: '#3cd68c',
                        border: '1px solid #3cd68c',
                        cursor: user ? 'pointer' : 'auto',
                      }}
                    >
                      ${item.imagePrice}
                    </div>
                  )}
                </div>
                <div className="bottom-info-container">
                  <div
                    className="title-badge"
                    style={{
                      marginRight: 5,
                      background: '#ffc53d1a',
                      color: '#ffc53d',
                      border: '1px solid #ffc53d',
                      cursor: user ? 'pointer' : 'auto',
                    }}
                  >
                    {index + 1}/{totalItems}
                  </div>
                  {item.imageLikes > 0 && (
                    <div
                      className="title-badge"
                      style={{
                        marginRight: 5,
                        background: '#86ead433',
                        color: themeMode ? '#86ead4' : '#1f9178',
                        border: `1px solid ${
                          themeMode ? '#86ead4' : '#1f9178'
                        }`,
                        cursor: user ? 'pointer' : 'auto',
                      }}
                    >
                      {item.imageLikes}
                      {item.imageLikes > 1 ? ' people ' : ' person '}
                      {item.imageLikes > 1 ? ' like ' : ' likes '} this
                    </div>
                  )}
                  <div className="img-settings-container">
                    <IconButton
                      style={{ height: '30px', width: '30px' }}
                      onClick={() => likeImage(item)}
                      disabled={disableLike ? true : false}
                    >
                      <ThumbUpAltIcon
                        style={{
                          color: liked[item._id] ? '#86ead4' : 'grey',
                          fontSize: 25,
                        }}
                      />
                    </IconButton>

                    {user ? (
                      <Tooltip
                        title="click to delete photo"
                        placement="top"
                        arrow
                      >
                        <IconButton
                          style={{ height: '30px', width: '30px' }}
                          onClick={() => {
                            handleDelete(item._id);
                            setPhotos([
                              ...photos.slice(0, index),
                              ...photos.slice(index + 1, photos.length),
                            ]);
                          }}
                        >
                          <DeleteIcon
                            style={{ color: '#d43a57', fontSize: 25 }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* Old carousel */}
              <div
                className={modalIsOpen ? 'photomodal' : 'hidden-photomodal'}
                key={item}
                onClick={() => closeModal()}
              >
                {modalIsOpen ? (
                  <div
                    className="photo-modal-container"
                    onMouseEnter={() => setModalInfoView(true)}
                    onMouseLeave={() => setModalInfoView(false)}
                  >
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        height: 40,
                        width: 40,
                        zIndex: 110,
                      }}
                    >
                      <CloseIcon style={{ fontSize: 30, color: '#e54666' }} />
                    </IconButton>
                    <div
                      className="next-photo-container"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNextClick(index + 1, item);
                      }}
                    >
                      <IconButton style={{ height: 60, width: 60 }}>
                        <NavigateNextIcon
                          className="button"
                          style={{
                            fontSize: 70,
                          }}
                        />
                      </IconButton>
                    </div>
                    <div
                      className="prev-photo-container"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePrevClick();
                      }}
                    >
                      <IconButton style={{ height: 60, width: 60 }}>
                        <NavigateNextIcon
                          className="button"
                          style={{
                            transform: 'rotate(180deg)',
                            fontSize: 70,
                          }}
                        />
                      </IconButton>
                    </div>
                    <div
                      className="modal-info-container"
                      style={{ opacity: modalInfoView ? 1 : 0 }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className="title-badge"
                        style={{
                          background: '#0da0f224',
                          color: '#0da0f2',
                          border: '1px solid #0da0f2',
                          cursor: user ? 'pointer' : 'auto',
                          fontSize: 20,
                        }}
                      >
                        {modalPicInfo.title
                          ? modalPicInfo.title
                          : `Item ${modalIndex + 1}`}
                      </div>
                      {modalPicInfo.price && (
                        <div
                          className="title-badge"
                          style={{
                            marginLeft: 5,
                            background: '#46fea526',
                            color: '#3cd68c',
                            border: '1px solid #3cd68c',
                            cursor: user ? 'pointer' : 'auto',
                            fontSize: 15,
                          }}
                        >
                          ${modalPicInfo.price}
                        </div>
                      )}
                      <div
                        className="title-badge"
                        style={{
                          marginLeft: 5,
                          background: '#ffc53d1a',
                          color: '#ffc53d',
                          border: '1px solid #ffc53d',
                          cursor: user ? 'pointer' : 'auto',
                          fontSize: 15,
                        }}
                      >
                        {modalIndex + 1}/{photos.length}
                      </div>
                    </div>
                    <div
                      className="enlarged-photo-container"
                      style={{
                        background: themeMode ? '#212225' : 'white',
                      }}
                    >
                      <img
                        className="enlarged-photo"
                        src={modalPicInfo.photo}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

export default Photos1;

// const { data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
//   ['getPhotos'],
//   async ({ pageParam = page }) => {
//     const response = await fetchPost(pageParam);
//     return response;
//   },
//   {
//     getNextPageParam: (_, pages) => {
//       return pages.length + 1;
//     },
//     initialData: {
//       pages: [photos.slice(0, limit)],
//       pageParams: [1],
//     },
//   }
// );

// const lastPostRef = useRef(null);
// const { ref, entry } = useIntersection({
//   root: lastPostRef.current,
//   threshold: 1,
// });

// useEffect(() => {
//   if (entry.isIntersecting) fetchNextPage();
// }, [entry]);

// const posts = data.pages.flatMap((page) => page);
