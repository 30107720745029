import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Nav from '../nav';
import './sales.css';
import './itemcard.css';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Redux stuff
import decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import {
  Button,
  TextField,
  Tooltip,
  IconButton,
  Icon,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import { useHistory, useLocation } from 'react-router-dom';
//

import Photos1 from './PhotoComponents/Photos1';
//

import Footer from '../footer/footer';
//

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SendIcon from '@mui/icons-material/Send';

function Sales(props) {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('themeMode') === 'true' ? true : false
  );
  const toggleThemeMode = () => {
    setThemeMode((prevMode) => !prevMode);
  };

  const [editingDescription, setEditingDescription] = useState(false);

  // User signed in
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const logoutExpire = () => {
    dispatch({ type: 'LOGOUT' });
    history.push('/Login');
    setUser(null);
    window.location.reload(false);
  };
  //
  const fetchDescription = async () => {
    // axios.get('https://ahes-v2.herokuapp.com/description/').then((data) => {
    try {
      await axios
        .get(
          'https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/description'
        )
        .then((data) => {
          setValue(data.data[0].description);
          console.log(data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [value, setValue] = useState('');
  const [currentTextValue, setCurrentTextValue] = useState(value);
  const postDescription = (e) => {
    // console.log('works');
    // e.preventDefault();
    const object = {
      description: currentTextValue,
    };
    axios
      .post(
        'https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/description',
        object
      )
      .then(() => {
        setValue(currentTextValue);
        setEditingDescription(null);
        fetchDescription();
      });

    // history.push('/Sales');
  };

  useEffect(() => {
    fetchDescription();
    const token = user?.token;
    // JWT
    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 2000 < new Date().getTime()) logoutExpire();
    }
    setUser(JSON.parse(localStorage.getItem('profile')));
    localStorage.setItem('themeMode', themeMode);
  }, [themeMode, location]);

  return (
    <div className="">
      <Nav
        themeBtn={true}
        bg={themeMode ? '#272a2d' : 'white'}
        mobileBG={themeMode}
        linkColor={themeMode ? '#e3e3e3' : '#272a2d'}
        setTheme={toggleThemeMode}
        theme={themeMode}
      />
      <div
        className="gallery-cont"
        style={{
          // height: photos.length <= 0 ? '100vh' : '100%',
          background: themeMode ? '#111113' : '#d43a57',
        }}
      >
        <div className="row-1">
          <div
            className={`sales-container`}
            style={{
              background: themeMode ? '#212225' : 'whitesmoke',
            }}
          >
            <div className="sales-h1-container">
              <h1
                className="sale-h1"
                style={{ color: themeMode ? '#e3e3e3' : 'black' }}
              >
                Items for sale!
              </h1>
            </div>
            <div className="description-container">
              {editingDescription ? (
                <div className="textfield-description-container">
                  <TextField
                    variant="outlined"
                    label="Edit Description"
                    fullWidth
                    defaultValue={value}
                    value={currentTextValue}
                    onChange={(e) => {
                      setCurrentTextValue(e.target.value);
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: themeMode && 'white',
                      },
                      '& label': {
                        color: themeMode && '#0da0f2',
                      },

                      // focused color for input with variant='outlined'
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: themeMode && '#0da0f2',
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        color: themeMode ? 'white' : 'black',
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip
                            arrow
                            placement="top"
                            title="Exit edit description"
                          >
                            <IconButton
                              onClick={() =>
                                setEditingDescription(!editingDescription)
                              }
                            >
                              <ModeEditIcon
                                style={{ color: '#08a2f4', fontSize: 20 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            arrow
                            placement="top"
                            title="Submit description edit"
                          >
                            <IconButton onClick={() => postDescription()}>
                              <SendIcon
                                style={{ color: '#08a2f4', fontSize: 20 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              ) : (
                <div className="edit-description-container">
                  <p
                    className="description-p"
                    onClick={() => {
                      {
                        user && setEditingDescription(!editingDescription);
                      }
                    }}
                    style={{
                      border: user && '2px dashed #0da0f236',
                      borderRadius: user && '5px',
                      cursor: user && 'pointer',
                      color: themeMode ? '#e3e3e3' : 'black',
                    }}
                  >
                    {value}
                  </p>
                </div>
              )}
            </div>
          </div>
          <Photos1 themeMode={themeMode} user={user} />
          {/* {loading ? (
            <div
              className="photo-container"
              style={{
                background: themeMode ? '#212225' : 'whitesmoke',
                flexDirection: 'column',
                alignItems: 'center',
                // height: 500,
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            
            <div
              className="photo-container"
              style={{
                background: themeMode ? '#212225' : 'whitesmoke',
                alignItems: photos.length <= 3 ? 'flex-start' : 'center',
                height: photos.length <= 3 && null,
              }}
            >
              
            </div>
          )} */}
        </div>
      </div>
      <Footer theme={themeMode} />
    </div>
  );
}

export default Sales;
