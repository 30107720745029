import React from "react";

function Redstar(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 -5 69 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="redstar"
        d="M28.7662 37.0763C27.9331 37.073 27.1227 36.8046 26.4522 36.3101L20.8758 32.2587C20.4131 31.9208 19.855 31.7387 19.2821 31.7387C18.7092 31.7387 18.1511 31.9208 17.6884 32.2587L12.112 36.3101C11.4395 36.7987 10.6295 37.0619 9.79824 37.0619C8.96696 37.0619 8.15699 36.7988 7.48443 36.3102C6.81187 35.8217 6.31125 35.1327 6.05429 34.3422C5.79732 33.5516 5.7972 32.6999 6.05398 31.9093L8.18408 25.354C8.36215 24.8094 8.36273 24.2224 8.18573 23.6774C8.00873 23.1325 7.66328 22.6578 7.19918 22.3218L1.62277 18.2704C0.950212 17.7818 0.449588 17.0929 0.192684 16.3022C-0.06422 15.5116 -0.0642257 14.6599 0.192653 13.8693C0.449532 13.0787 0.950136 12.3897 1.62268 11.9011C2.29522 11.4124 3.10518 11.1493 3.93649 11.1493H10.8295C11.4023 11.1503 11.9607 10.9694 12.4241 10.6328C12.8876 10.2961 13.2322 9.82093 13.4082 9.27582L15.538 2.72046C15.7948 1.92974 16.2954 1.24066 16.968 0.751953C17.6406 0.263245 18.4507 0 19.2821 0C20.1135 0 20.9235 0.263245 21.5961 0.751953C22.2687 1.24066 22.7693 1.92974 23.0261 2.72046L25.1559 9.27582C25.332 9.82093 25.6765 10.2961 26.14 10.6328C26.6034 10.9694 27.1618 11.1503 27.7346 11.1493H34.6276C35.4589 11.1493 36.2689 11.4125 36.9414 11.9011C37.6139 12.3898 38.1145 13.0788 38.3713 13.8694C38.6282 14.66 38.6282 15.5116 38.3713 16.3022C38.1144 17.0929 37.6139 17.7818 36.9413 18.2704L31.3649 22.3218C30.9008 22.6578 30.5554 23.1325 30.3784 23.6774C30.2014 24.2224 30.202 24.8094 30.38 25.354L32.5101 31.9093C32.7764 32.6991 32.7811 33.5538 32.5234 34.3464C32.2658 35.1391 31.7596 35.8277 31.0799 36.3101C30.4095 36.8046 29.5992 37.0729 28.7662 37.0763Z"
        fill="#E63946"
      />
    </svg>
  );
}

export default Redstar;
