import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/home.css';
import landingpage from '../assets/landingpage.png';
import Aboutbtn from './aboutbtn';
function Landingpage(props) {
  return (
    <div className="landing-page-cnt" id={props.topid}>
      <div className="landingpage-bg"></div>

      <div className="col-1">
        <h1 className="welcome-h1">
          Welcome, <br></br> to American Household Estate Sales
        </h1>
        <div className="link-container">
          <div className="link-1">
            <Link to="/Sales" className="button-1">
              Upcoming Sales
            </Link>
          </div>

          <Aboutbtn
            divclassname="link-1"
            buttonclass="button-1 about-btn"
            scrollto={props.scrollto}
          />
        </div>
      </div>
      <div className="col-2">
        <div className="item-img"></div>
      </div>
    </div>
  );
}

export default Landingpage;
