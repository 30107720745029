import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { TextField, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
function Subscribe() {
  const CssTextField = withStyles({
    root: {
      '& .MuiInputBase-input': {
        color: 'black',
      },
      '& label.Mui-focused': {
        color: '#e63946',
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'yellow',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#08a2f4',
          color: 'white',
        },
        '&:hover fieldset': {
          borderColor: '#e63946',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#e63946',
          color: 'white',
        },
        input: {
          color: '#08a2f4',
        },
      },
    },
  })(TextField);

  const [state, setState] = useState({
    email: '',
  });

  const handleChange = (e) => {
    setState({ email: e.target.value.trim() });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (state.email) {
      fetch(`https://ahes-v2.herokuapp.com/api/memberAdd?email=${state.email}`)
        .then((res) => res.json())
        .then((res) => console.log(res))
        .catch((err) => console.warn(err));
    }
  };

  return (
    <div className="info-mail">
      <h1 className="newsletter-h1">Subscribe to our newsletter!</h1>
      <p className="newsletter-p">
        Get an advantage by subscribing to our newsletter to get weekly updates
        on whats up for sale!
      </p>
      <form className="subscribe-form" onSubmit={handleSubmit}>
        <input
          className="subscribe-email"
          name="email"
          onChange={handleChange}
          value={state.email}
          placeholder="Enter your email here"
          type="email"
        />
        <Button
          style={{ height: 55, marginTop: 0 }}
          className="submit-button"
          variant="outlined"
          type="submit"
        >
          Subscribe
        </Button>
      </form>
    </div>
  );
}

export default Subscribe;
