import React, { useState, useEffect, useRef } from 'react';
// Axios
import axios from 'axios';
// Mui
import {
  Dialog,
  IconButton,
  AppBar,
  Toolbar,
  Tooltip,
  Tabs,
  Tab,
} from '@mui/material';
//
import { FaArrowDown } from 'react-icons/fa';
import NotesIcon from '@mui/icons-material/Notes';
import ImageIcon from '@mui/icons-material/Image';
import { useHistory, Link } from 'react-router-dom';
import './admin.css';
// Components
import UploadDescription from './adminPieces/uploadDescription';
import Textbox from './textbox';
import Nav from '../nav';
import Photos from './photos';
import Logout from './logout';
import UploadPhotos from './adminPieces/uploadPhotos';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ position: 'relative', height: '95%', width: '100%' }}
    >
      {value === index && (
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
          {children}
        </div>
      )}
    </div>
  );
}

function Admin(props) {
  //
  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const history = useHistory();

  //
  // const [textBoxValue, setTextBoxValue] = useState('');
  // const currentTextValue = useRef(null);
  //

  // const handleSubmit = (e) => {
  //   console.log('works');
  //   e.preventDefault();
  //   const object = {
  //     description: currentTextValue.current.value,
  //   };
  //   axios
  //     .put(
  //       'https://ahesbackend.americanhouseholdestatesales.com/api/description',
  //       object
  //     )
  //     .then(() => {
  //       fetchInitialText();
  //     });

  //   history.push('/Sales');
  // };

  // Login code
  const checkLoggedIn = () => {
    const loggedIn = localStorage.getItem('profile');
    if (loggedIn === false || loggedIn === null) {
      history.push('/login');
    }
  };
  useEffect(() => {
    checkLoggedIn();
  }, []);

  return (
    <div>
      <Nav
        setThem={props.setTheme}
        headerColor="#fff"
        // navcolor="#fff"
        // navBackground="none"
        // navBorderColor="#fff"
        // activeNavColor="#08a2f4"
        // activeNavBorderColor="#08a2f4"
      />
      <div className="admin-container">
        <div className="controls-container">
          <div className=""></div>
          <div className="tab-container">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="secondary"
              variant="fullWidth"
            >
              <Tab
                label="Upload Description"
                icon={<NotesIcon style={{ fontSize: 35 }} />}
                {...a11yProps(0)}
              />
              <Tab
                label="Upload Images"
                icon={<ImageIcon style={{ fontSize: 35 }} />}
                {...a11yProps(1)}
              />
            </Tabs>
          </div>
          <div className="tab-content-container">
            <TabPanel value={value} index={0} dir="ltr">
              <UploadDescription />
            </TabPanel>
            <TabPanel value={value} index={1} dir="ltr">
              <UploadPhotos />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
