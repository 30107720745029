import React, { useState, useEffect } from 'react';
// Dependencies
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BiLogIn } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';

// Components

// CSS
import '../styles/nav.css';
// images
import logo from '../assets/logo.png';
// Redux stuff
import decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { Button, IconButton } from '@mui/material';
import ThemeButton from './sales/themeButton';

function Nav(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
    history.push('/login');
    setUser(null);
    window.location.reload(false);
  };
  const logoutExpire = () => {
    dispatch({ type: 'LOGOUT' });
    history.push('/Login');
    setUser(null);
    window.location.reload(false);
  };

  const [click, setClick] = useState(false);

  // Active navbar stat
  const [nav, setNav] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNav(true);
    } else {
      setNav(false);
    }
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logoutExpire();
    }
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);

  window.addEventListener('scroll', changeBackground);
  return (
    <nav
      className={nav ? 'nav active' : 'nav'}
      style={{ background: props.bg }}
    >
      <Link to="/" className="brand">
        <img src={logo} className="logo-img" alt="" />
      </Link>
      <div className="menu-icon" onClick={handleClick}>
        <i
          style={{ color: props.linkColor }}
          className={click ? 'fas fa-times' : 'fas fa-bars'}
        />
      </div>
      {props.themeBtn && (
        <ThemeButton
          className="mobile-theme-btn"
          setTheme={props.setTheme}
          theme={props.theme}
        />
      )}
      <ul
        className={
          click
            ? `${!props.mobileBG ? 'nav-menu active' : 'nav-menu activeDark'}`
            : 'nav-menu'
        }
      >
        <li className="nav-item">
          <Link
            to="/"
            className="nav-links"
            style={{ color: props.linkColor }}
            onClick={closeMobileMenu}
          >
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/Sales"
            className="nav-links"
            style={{ color: props.linkColor }}
            onClick={closeMobileMenu}
          >
            Upcoming Sales
          </Link>
        </li>
        <li className="nav-item" style={{ color: 'props.linkColor' }}>
          <Link
            to="/Contact"
            className="nav-links"
            style={{ color: props.linkColor }}
            onClick={closeMobileMenu}
          >
            Contact
          </Link>
        </li>
        {user ? (
          <li className="nav-item">
            <Link
              to="/Admin"
              className="nav-links"
              style={{ color: props.linkColor }}
              onClick={closeMobileMenu}
            >
              Admin
            </Link>
          </li>
        ) : null}
        {user ? (
          <li className="nav-item">
            <Button
              onClick={logout}
              variant="outlined"
              style={{
                color: !nav ? props.navcolor : props.activeNavColor,
                background: !nav
                  ? props.navBackground
                  : props.activeNavBackground,
                borderColor: !nav
                  ? props.navBorderColor
                  : props.activeNavBorderColor,
              }}
            >
              Logout {user.result.username}
            </Button>
          </li>
        ) : null}
        {props.themeBtn && (
          <ThemeButton
            className="nav-item themeButton"
            setTheme={props.setTheme}
            theme={props.theme}
          />
        )}
      </ul>
    </nav>
  );
}

export default Nav;
