import React, { useState, useRef, useEffect } from 'react';
//
import '../admin.css';
import FileBase64 from 'react-file-base64';
import axios from 'axios';
import compress from 'compress-base64';
//
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
//
import { createPhotos } from '../../../actions/imagePost';
import { TextField, Button } from '@mui/material';
function UploadPhotos() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const [textBoxValue, setTextBoxValue] = useState('');
  // const currentTextValue = useRef(null);
  const [currentTextValue, setCurrentTextValue] = useState('');
  const [descriptionId, setDescriptionId] = useState('');

  const fetchInitialText = () => {
    axios
      .get(
        'https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/description'
      )
      .then((data) => {
        setTextBoxValue(data.data[0].description);
        setDescriptionId(data.data[0]._id);
        console.log(descriptionId);
      });
  };

  const postDescription = (e) => {
    // console.log('works');
    e.preventDefault();
    const object = {
      description: currentTextValue,
    };
    axios
      .post(
        'https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/description',
        object
      )
      .then(() => {
        fetchInitialText();
      });

    // history.push('/Sales');
  };
  const deleteDescription = (descriptionId) => {
    console.log(descriptionId);
    axios
      .delete(
        `https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/description/${descriptionId}`
      )
      .then(() => {
        fetchInitialText();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchInitialText();
  }, []);

  return (
    <div className="description-container">
      <div className="upload-container">
        <div className="header-container">
          <h1>Upload description here:</h1>
        </div>
        <div className="textarea">
          <TextField
            // ref={currentTextValue}
            onChange={(e) => {
              setCurrentTextValue(e.target.value);
              // console.log(currentTextValue);
            }}
            required
            style={{ width: '100%' }}
            sx={{
              '& .MuiInputLabel-root': {
                color: 'white',
              }, //styles the label
              '& .MuiOutlinedInput-root': {
                '& > fieldset': {
                  borderColor: '#8d8d8d',
                  color: '#8d8d8d',
                },
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: 'white',
                },
              },
              '& .MuiOutlinedInput-root:hover': {
                '& > fieldset': {
                  borderColor: '#8d8d8d',
                },
              },
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: 'white',
              },
            }}
            InputLabelProps={{
              focused: false,
            }}
            label="Enter description"
            variant="outlined"
            multiline
            rows={15}
          />
          <div className="buttons-container">
            <Button
              onClick={(e) => postDescription(e)}
              type="submit"
              variant="contained"
              style={{
                width: '45%',
                color: '#fff',
                background: '#0da0f2',
                marginTop: 20,
              }}
            >
              post description
            </Button>

            <Button
              onClick={() => deleteDescription(descriptionId)}
              type="submit"
              style={{
                variant: 'contained',
                width: '45%',
                background: '#d43a57',
                color: '#fff',
                marginTop: 20,
              }}
            >
              Delete description
            </Button>
          </div>
        </div>
      </div>
      <div className="mid-container"></div>
      <div className="result-container">
        <div className="result-header-container">
          <h1 className="result-h1">Description result:</h1>
        </div>
        <div className="result-box">
          <p>
            {textBoxValue ? textBoxValue : 'Description result will be here...'}
          </p>
        </div>
      </div>
    </div>
  );
}

export default UploadPhotos;
