import axios from 'axios';

const API = axios.create({
  baseURL: 'https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com',
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem('profile')).token
    }`;
  }

  return req;
});

// functions for image posts
export const fetchPhotos = () => API.get('/image');
export const createPost = (newPost) => API.post('/image', newPost);
export const updatePost = (id, updatedPost) =>
  API.patch(`/image/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/image/${id}`);
// functions for descriptions
export const fetchDescription = () => API.get('/description');
export const createDescription = (newPost) => API.post('/description', newPost);
export const updateDescription = (id, updatedDescription) =>
  API.patch(`/description/${id}`, updatedDescription);
export const deleteDescription = (id) => API.delete(`/description/${id}`);
// functions for singing in
export const signIn = (formData) => API.post('/admin/signin', formData);
export const signUp = (formData) => API.post('/admin/signup', formData);
