import React, { useState } from 'react';
// CSS
import './footer.css';
import axios from 'axios';
// images
import ahesLogo from '../../assets/logo.png';
// dependencies
import { TextField, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
function Footer(props) {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        'https://seahorse-app-woppp.ondigitalocean.app/ahes.backendv2.com/newsletter',
        { email }
      );
      setEmail('');
      alert('Thank you for subscribing!');
    } catch (error) {
      console.error(error);
      alert('There was a problem subscribing. Please try again later.');
    }
  };
  return (
    <div
      className="footer-container"
      style={{ background: props.theme ? '#19191c' : '#011327' }}
    >
      <div className="footerheader">
        <img
          src={ahesLogo}
          className="footer-logo"
          alt="american house hold estate sales logo"
        />
      </div>
      <div className="footer-about-section">
        <h1>About us</h1>
        <p>
          AHES, also known as American House Hold Estate sales has been serving
          the community of Albuquerque New Mexico, Rio Rancho and all over New
          Mexico as a whole for the past 19 years. We at ahes provide
          professional Estate Sale services that include: Movings, Love ones
          passings and helping folks in these hard times. Our estate sales
          usually takes around one to two weeks from start to finish.
        </p>
      </div>
      <div className="footer-links">
        <h1>Important links</h1>
        <Link to="/" className="link">
          Home
        </Link>
        <Link to="/Sales" className="link">
          Upcoming Sales
        </Link>
        <Link to="/Contact" className="link">
          Contact us
        </Link>
        <Link to="/About" className="link">
          About us
        </Link>
      </div>
      <div className="footer-subscribe-email">
        <div className="sub-content">
          <h1>Subscribe to our newsletter!</h1>
          <p>By subscribing youll be receiving weekly updates of each sale!</p>
        </div>
        <div className="sub-inputs">
          <TextField
            label="Email here"
            className="textField"
            name="Email"
            sx={{
              '& label.Mui-focused': {
                color: 'white',
              },
              '& label': {
                color: '#0da0f2',
              },

              // focused color for input with variant='outlined'
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#0da0f2',
                },
              },
            }}
            fullWidth
            margin="normal"
            autoComplete="off"
            size="small"
            type="text"
            required
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              style: { color: '#fff' },
              form: {
                autoComplete: 'off',
              },
              WebkitBoxShadow: 'transparent',
            }}
          />
          <Button
            style={{ height: 40, marginBottom: 3 }}
            className="sub-button"
            variant="outlined"
            onClick={handleSubmit}
          >
            subscribe
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
