import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// CSS
import '../../styles/login.css';
import Logo from '../../assets/logo.png';
import Nav from '../nav';
// Logos
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Redux actions
import { signin, signup } from '../../actions/auth';
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';

const signUpState = {
  username: '',
  password: '',
};

function Login(props) {
  const [warningMessage, setWarningMessage] = useState('');
  const username = useRef(null);
  const password = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  // const setLoggedInTrue = (response) => {
  //   if (response === true) {
  //     localStorage.setItem('loggedIn', true);
  //   } else {
  //     setWarningMessage('Wrong Credentials');
  //   }
  // };
  // old
  // const handleSubmitForm = (e) => {
  //   e.preventDefault();
  //   const secretUsername = 'ahesales1';
  //   const secretPassword = 'frank12345';
  //   const currentUsername = username.current.value;
  //   const currentPassword = password.current.value;
  //   if (
  //     secretUsername === currentUsername &&
  //     currentPassword === secretPassword
  //   ) {
  //     setLoggedInTrue(true);
  //     history.push('/admin'); // hi
  //   } else {
  //     setLoggedInTrue(false);
  //   }
  // };

  const [formData, setFormData] = useState(signUpState);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(signin(formData, history));
    // history.push('/admin');
  };
  const handleSignUp = (e) => {
    e.preventDefault();
    dispatch(signup(formData, history));
  };

  const [showPassword, setShowPassword] = useState(false);
  return (
    <Container fluid className="login-container">
      <Nav setTheme={props.setTheme} />

      <img src={Logo} className="top-logo" alt="" />
      <div className="login-form-cnt">
        <h1 className="login-h1">Log into your account</h1>
        <form onSubmit={(e) => handleLogin(e)} className="form">
          <TextField
            type="username"
            name="username"
            label="Username"
            style={{ width: '80%', marginBottom: 20 }}
            onChange={handleChange}
            required
            value={formData.username}
            // autoComplete="new-password"
          />
          <TextField
            type={showPassword ? 'text' : 'password'}
            name="password"
            label="Password"
            style={{ width: '80%', marginBottom: 20 }}
            onChange={handleChange}
            value={formData.password}
            required
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    arrow
                    placement="right"
                    title={showPassword ? 'Hide password' : 'Show password'}
                  >
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="outlined"
            type="submit"
            value="Login"
            className="login-button"
          >
            Login
          </Button>
        </form>
        {/* Sign up form */}
        {/* <form
          style={{ position: 'absolute', top: '100%' }}
          onSubmit={(e) => handleSignUp(e)}
        >
          <TextField name="username" label="username" onChange={handleChange} />
          <TextField
            name="password"
            password="password"
            label="password"
            onChange={handleChange}
          />
          <Button type="submit" variant="outlined">
            Submit
          </Button>
        </form> */}
      </div>
      <div className="login-background-container"></div>
    </Container>
  );
}

export default Login;
