import * as api from '../api';

// Action creators
// Action creators
export const STORE_IMAGES = 'STORE_IMAGES';

export const storeItems = (items) => ({
  type: STORE_IMAGES,
  payload: items,
});

// export const getPhotos = () => async (dispatch) => {
//   try {
//     const { data } = await api.fetchPhotos();
//     dispatch({ type: 'FETCH_ALL', payload: data });
//   } catch (error) {
//     console.log(error);
//   }
//   // const action = { type: "FETCH_ALL", payload: [] };
// };

// // Create post function
// export const createPhotos = (post) => async (dispatch) => {
//   try {
//     const { data } = await api.createPost(post);

//     dispatch({ type: 'CREATE', payload: data });
//   } catch (error) {
//     console.log(error.message);
//   }
// };

// // Update post
// export const updatePost = (id, post) => async (dispatch) => {
//   try {
//     const { data } = await api.updatePost(id, post);

//     dispatch({ type: 'UPDATE', payload: data });
//   } catch (error) {
//     console.log(error);
//   }
// };

// //
// export const deletePost = (id) => async (dispatch) => {
//   try {
//     await api.deletePost(id);

//     dispatch({ type: 'DELETE', payload: id });
//   } catch (error) {
//     console.log(error);
//   }
// };
