import { IconButton } from '@mui/material';
import React from 'react';
//
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';

function ThemeButton(props) {
  return (
    <li className={props.className}>
      <IconButton
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          cursor: 'pointer',
        }}
        onClick={props.setTheme}
      >
        {props.theme ? (
          <DarkModeIcon style={{ color: 'white', fontSize: 30 }} />
        ) : (
          <WbSunnyIcon />
        )}
      </IconButton>
    </li>
  );
}

export default ThemeButton;
